/*************************************************************/
/********************* TABLE OF CONTENTS *********************/
/*************************************************************/
/**                                                         **/
/** General Styles                                          **/
/** Generic Styles                                          **/
/** Menus                                                   **/
/**  - Nav Pills                                            **/
/**  - Nestor Main Menu                                     **/
/** Portfolio                                               **/
/**  - Portfolio Page                                       **/
/**  - Our Work 1                                           **/
/** Team                                                    **/
/** Social Networks                                         **/
/**  - Social Networks Top Header                           **/
/**  - Social Networks Footer                               **/
/** Pagination                                              **/
/** Tabs                                                    **/
/** Accordions                                              **/
/** Pricing tables                                          **/
/**  - Layout 1                                             **/
/** Buttons                                                 **/
/** Labels                                                  **/
/** Progress Bars                                           **/
/** Forms                                                   **/
/** Colors & Backgrounds                                    **/
/**  - Text Color Palette                                   **/
/**  - Text Colors                                          **/
/**  - Background Colors                                    **/
/**                                                         **/
/*************************************************************/
/*************************************************************/



/*************************************************************/
/*********************** General Styles **********************/
/*************************************************************/

a {
  /*color: #c2b49a;*/
  color: #967451;
  font-weight: bold;
}



/*************************************************************/
/*********************** Generic Styles **********************/
/*************************************************************/

.overlay-color-theme {
  background: rgba(192,179,156,.8);
}



/*************************************************************/
/*************************** Menus ***************************/
/*************************************************************/

/** Nav Pills **/
.nav-pills > li > a:hover,
.nav-pills > li > a:focus,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #c2b49a;
}

/** Nestor Main Menu **/
.nestor-main-menu .active > a,
.nestor-main-menu .nav .open > a,
.nestor-main-menu .nav .open > a:hover,
.nestor-main-menu .nav .open > a:focus,
.nestor-main-menu .nav > li > a:hover,
.nestor-main-menu .nav > li > a:focus,
.nestor-main-menu .nav > li:hover > a,
.nestor-main-menu .nav > li:focus > a {
  color: #c2b49a;
}

.nestor-main-menu .dropdown-menu > .active > a,
.nestor-main-menu .dropdown-menu > li > a:hover,
.nestor-main-menu .dropdown-menu > li > a:focus {
  color: #c2b49a;
}

@media (max-width: 767px) {
  
  .nestor-main-menu .navbar-toggle {
    border: 1px solid #c2b49a;
    background: #c2b49a;
  }
  
}



/*************************************************************/
/************************* Portfolio *************************/
/*************************************************************/

/** Portfolio Page **/
.portfolio-overlay {
  background: rgba(192,179,156,.8);
}

/** Our Work 1 **/
.our-work-1-overlay {
  background: rgba(192,179,156,.8);
}



/*************************************************************/
/**************************** Team ***************************/
/*************************************************************/

.team-item-overlay {
  background: rgba(192,179,156,.8);
}



/*************************************************************/
/********************** Social Networks **********************/
/*************************************************************/

/** Social Networks Top Header **/
.social-networks-top-header a:hover,
.social-networks-top-header a:focus,
.social-networks-top-header a:active {
  color: #c2b49a;
}

/** Social Networks Footer **/
.social-networks-footer a:hover,
.social-networks-footer a:focus,
.social-networks-footer a:active {
  color: #c2b49a;
}



/*************************************************************/
/************************* Pagination ************************/
/*************************************************************/

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #c2b49a;
}



/*************************************************************/
/**************************** Tabs ***************************/
/*************************************************************/

.nav-tabs > li > a:hover {
  color: #c2b49a;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border: 1px solid #c2b49a;
  color: #c2b49a;
}

.nav-tabs.nav-justified > li > a:hover {
  color: #c2b49a;
}

.nav-tabs.nav-justified > .active >a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #c2b49a;
  color: #c2b49a;
}



/*************************************************************/
/************************* Accordions ************************/
/*************************************************************/

.panel-primary > .panel-heading {
  border: 1px solid #c2b49a;
}



/*************************************************************/
/*********************** Pricing tables **********************/
/*************************************************************/

/** Layout 1 **/
.pricing-tables-1 .pricing-table-promotional .pricing-table-price {
  background-color: #c2b49a;
}



/*************************************************************/
/************************** Buttons **************************/
/*************************************************************/

.btn-primary {
  border-color: #c2b49a;
  color: #c2b49a;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  border-color: #c2b49a;
  background-color: #c2b49a;
}

.btn-link {
  color: #c2b49a;
}



/*************************************************************/
/*************************** Labels **************************/
/*************************************************************/

.label-primary {
  background-color: #c2b49a;
}



/*************************************************************/
/*********************** Progress Bars ***********************/
/*************************************************************/

.progress-bar-primary {
  background-color: #c2b49a;
}



/*************************************************************/
/*************************** Forms ***************************/
/*************************************************************/

.form-control:focus {
  border-color: #c2b49a;
}



/*************************************************************/
/******************** Colors & Backgrounds *******************/
/*************************************************************/

/** Text Color Palette **/
.text-color-light .social-networks-footer a:hover,
.text-color-light .social-networks-footer a:focus,
.text-color-light .social-networks-footer a:active {
  color: #c2b49a;
}

/** Text Colors **/
.text-color-theme      { color: #c2b49a; }

/** Background Colors **/
.bg-color-theme        { background-color: #c2b49a; }