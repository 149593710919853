@font-face {
  font-family: 'Proxima Nova', sans-serif;
  src: url("/fonts/ProximaNova.woff2") format("woff2"),
       url("/fonts/ProximaNova.woff") format("woff");
}

@font-face {
  font-family: 'Averta', sans-serif;
  src: url("/fonts/AvertaDemo.woff2") format("woff2"),
       url("/fonts/AvertaDemo.woff") format("woff");
}

.text-justify {
    text-align: justify;
}


.sermon-search-button {
    padding: 2px 35px !important;
  }

  @media (max-width: 767px) {
    .nav-collapse{
        margin-top: -55px !important;
      }


  /*Stop mobile menu from flashing when losing focus*/
  .tb-megamenu .nav-collapse .nav > li > a:hover,
.tb-megamenu .nav-collapse .nav > li > a:focus,
.tb-megamenu .nav-collapse .dropdown-menu a:hover,
.tb-megamenu .nav-collapse .dropdown-menu a:focus {
  background-color: #fff;
  color: #75807f !important;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
  background-color: #fff;
  color: #75807f !important;
}
.nav .open > a, .nav .open > a:hover, .nav.open > a:focus{
  background-color: #fff;
  color: #75807f !important;
}
}

.tb-megamenu .nav-collapse, .tb-megamenu .nav-collapse .dropdown-menu,
.tb-megamenu .nav-collapse .nav > li > a, .tb-megamenu .nav-collapse .dropdown-menu a {
  background: #fff !important;
  color: #75807f !important;
}

.nestor-main-menu .navbar-toggle {
  border: 1px solid #d8a574 !important;
  background: #d8a574 !important;
  color: #fff !important;
}

@media (max-width: 767px) {

  .nav-collapse{
    margin-top: -55px !important;
  }

  /*Stop mobile menu from flashing when losing focus*/
  .tb-megamenu .nav-collapse .nav > li > a:hover,
.tb-megamenu .nav-collapse .nav > li > a:focus,
.tb-megamenu .nav-collapse .dropdown-menu a:hover,
.tb-megamenu .nav-collapse .dropdown-menu a:focus {
  background-color: #000;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
  background-color: #000;
  color: #65aad5 !important;
}
.nav .open > a, .nav .open > a:hover, .nav.open > a:focus{
  background-color: #000;
}
}

.navbar-header{
  text-align: center;
  width:100%;
  }
  @media (min-width:768px) and (max-width:979px){
  .navbar-toggle{
    display: inline-block;
    width: 250px;
    border-radius: 0;
    padding: 12px;
    float: none;   
  }
  .nav-collapse{
    margin-top: -55px !important;
  }
  }

  @media (max-width: 767px) {
    .desktop-only{
        display: none;
    }
}
@media (min-width: 768px) {
    .mobile-only{
        display: none;
        
    }
}

.resp-iframe-wrapper {

  position: relative;
  padding-bottom: 100%;
  height: 0;
}
.resp-iframe-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;

}

@media (min-width: 555px) and (max-width: 991px) {
  .resp-iframe-wrapper{
      padding-bottom: 75%;
      
  }
}

.latestSermon-img{
  width: 100% !important;
}

#carousel-container {
  margin-bottom: 40px;
}

.slick-slide img {
  margin: auto;
}

#range-container{
  width: 75%;
  display: inline-block;
}

.podcast-img{
  height: 70px;
  width: auto;
}

.footer-link{
  color: #f6ece3;
  font-weight: normal;
}

.sermon-table{
  width: 100%;
}

.sermon-table-container{
  overflow-x: auto
}

  
@media (max-width: 767px) {
  .xs-padding-top-20{
    padding-top: 20px;
  }}

  #logo-region > a > img{
    max-height: 120px;
  }


h1, h2, h3, h4, h5, h6 {
  color: '#d8a574';
  font-family: 'Averta';
}

body {
  font-family: 'Proxima Nova';
}