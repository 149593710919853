.notification-popup {
  font-size: 18px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;  
}
.notification-popup > span > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.notification-popup > span > .content {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
  overflow-y: scroll;
}
.notification-popup > span > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
}
.notification-popup > span > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 25px;
  right: -5px;
  top: -10px;
  font-size: 28px;
  background: #ffffff;
  border-radius: 30px;
  border: 1px solid #cfcece;
  opacity: 1 !important;
  overflow: ;
}

@media(max-width: 1000px){
  .popup-content{
    width: 100% !important;
  }
}